import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  InputAdornment,
  CircularProgress,
  Box,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  EmailOutlined,
  WhatsApp,
} from "@material-ui/icons";
import styled from "styled-components";
import WhatsappContact from "../components/WaContact";

function ContactFormAirtable() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [status, setStatus] = useState("");
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      email: !formData.email,
      phone: !formData.phone,
    };
    setStatus("");
    setErrors(newErrors);
    return !newErrors.name && !newErrors.email && !newErrors.phone;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    setSending(true);
    if (validateForm()) {
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appz7PWXHmLwO2NOH/spanishCitizenship",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer patNZwWMRQZO6D5Yl.46cb457085f9bcff4dda20064a2591758473834c7aca680295a2087e8d063c8f`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fields: {
                name: formData.name,
                email: formData.email,
                whatsapp: formData.phone,
              },
            }),
          }
        );

        if (response.ok) {
          setStatus("Form submitted successfully!");
          setFormData({ name: "", email: "", phone: "" });
          setSending(false);
        } else {
          setStatus("Error submitting the form. Please try again.");
          setSending(false);
        }
      } catch (error) {
        setStatus("There was a problem submitting the form.");
        setSending(false);
      }
    }
    setSending(false);
  };

  return (
    <Wrapper>
      <SectionDescent>
        Contact us to learn more about this service
      </SectionDescent>
      <WhatsappContact />
      <Form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label="Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={errors.name && !formData.name}
              helperText={
                errors.name && !formData.name ? "This field is required" : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlined style={{ color: "#00426b" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email && !formData.email}
              helperText={
                errors.email && !formData.email ? "This field is required" : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined style={{ color: "#00426b" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Whatsapp"
              variant="outlined"
              color="primary"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              error={errors.phone && !formData.phone}
              helperText={
                errors.phone && !formData.phone ? "This field is required" : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WhatsApp style={{ color: "#00426b" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            {sending ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ fontWeight: "800", fontSize: "16px", width: "150px" }}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      </Form>
      <p>{status}</p>
    </Wrapper>
  );
}

const SectionDescent = styled.h2`
  /* H2 */
  font-family: ${(props) => props.theme.fonts.primary.regular};
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #00426b;
`;

const Wrapper = styled(Box)`
  text-align: center;
  background-color: #ededed;
  padding: 15px;

  ${(props) => props.theme.mui.breakpoints.up("md")} {
  }
`;

const Form = styled.form`
  margin: 0 auto;
  ${(props) => props.theme.mui.breakpoints.up("md")} {
    max-width: 50%;
  }
`;

export default ContactFormAirtable;
