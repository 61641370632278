import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

import { useEscapePress, useOutsideElementClick } from "../lib/helpers";

/**
 * ModernMobileDrawer
 */

const ModernMobileDrawer = ({
  className,
  visible,
  onRequestClose,
  items,
  showCTA,
  ctaComponent,
}) => {
  const ref = useRef(null);

  const handleCloseButtonClick = () => {
    onRequestClose();
  };

  useEscapePress(onRequestClose, !visible);
  useOutsideElementClick(ref, onRequestClose, !visible);

  return (
    <Wrapper ref={ref} className={className} visible={visible}>
      {showCTA && <ComponentWrapper>{ctaComponent}</ComponentWrapper>}
      <Links>
        {items.map(
          ({ title, url, target, selected, IconComponent, onClick }, i) => {
            const last = i === items.length - 1;

            const handleClick = () => {
              onClick && onClick();
              handleCloseButtonClick();
            };

            return (
              <div key={title}>
                <ListItem button onClick={handleClick} target={target}>
                  {IconComponent && <IconComponent style={iconStyle} />}
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={url}
                  >
                    {title}
                  </Link>
                </ListItem>
                {!last && <Divider />}
              </div>
            );
          }
        )}
      </Links>
    </Wrapper>
  );
};

ModernMobileDrawer.propTypes = {
  items: PropTypes.array.isRequired,
  onRequestClose: PropTypes.func,
  visible: PropTypes.bool,
  className: PropTypes.string,
};

ModernMobileDrawer.defaultProps = {
  visible: false,
  className: "",
};

/**
 * Styles
 */

const iconStyle = {
  marginRight: 8,
  height: 20,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  position: absolute;

  transition: top 0.3s ease-in-out;
  top: ${(props) => (props.visible ? "60px" : "-550%")};
  z-index: 99999;
  padding: 0 10px;
  box-sizing: border-box;
`;

const ComponentWrapper = styled.div`
  width: 100%;
  display: block;
  margin: 10px 0px;
  ${(props) => props.theme.mui.breakpoints.up("md")} {
    display: none;
  }
`;

const Links = styled.div`
  width: 100%;
`;

/**
 * Exports
 */

export default ModernMobileDrawer;
