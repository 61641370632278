import React, { useEffect } from "react";
import styled from "styled-components";

import Nav from "../components/Nav";
import ContactFormAirtable from "../components/ContactFormAirtable";

const SpanishCitizenshipPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Root>
      <Nav sticky={true} />
      <Main>
        <ContentWrapper>
          <ContentImageHero
            desktop="./images/HeroImageCitizenship.jpg"
            mobile="./images/HeroImageCitizenship.jpg"
          ></ContentImageHero>
          <Section>
            <ContentSection>
              <Text justify>
                <Strong>HOXTON CONSULTING</Strong> We are experts in
                administrative services with support in Spain, the USA, and
                Latin America. Contact us for a free consultation to help you
                determine the prima facie viability of your case and the next
                steps. *We are not a law firm.
              </Text>
              <SectionDescent>
                Spanish passport documents preparation services
              </SectionDescent>
              <Text justify>
                Navigating the process of obtaining a Spanish passport as
                grandchild or great grandchild of a Spanish citizen can be
                complex and time-consuming. Our expert consultants specialize in
                guiding clients through every step of gathering and properly
                authorizing the required documentation.
              </Text>
              <List>
                <li>
                  <Strong>
                    Comprehensive document checklists tailored to your specific
                    situation
                  </Strong>
                </li>
                <li>
                  <Strong>
                    Guidance on obtaining hard-to-find historical records and
                    vital documents
                  </Strong>
                </li>
                <li>
                  <Strong>
                    Assistance with translations and proper
                    legalization/apostille of foreign documents
                  </Strong>
                </li>
                <li>
                  <Strong>
                    Support in preparing your application package for submission
                  </Strong>
                </li>
              </List>
              <Text justify>
                Our team of consultants has in-depth knowledge of Spanish
                citizenship and passport requirements. Don't risk delays or
                rejection due to incomplete documentation. Let our experienced
                consultants handle the complexities so you can focus on your
                exciting journey to Spanish citizenship. Contact us today for a
                free consultation on your Spanish passport document needs.
              </Text>
            </ContentSection>
          </Section>

          <ContactFormAirtable />

          <Section>
            <ContentSection>
              <SectionDescent>
                The advantages of Spanish Citizenship
              </SectionDescent>
              <Text justify>
                Spanish citizenship offers several significant benefits:
              </Text>
              <List>
                <li>
                  <Strong>Freedom of Movement in the European Union: </Strong>
                  Spanish citizens can live, work, and study freely in any EU
                  country without the need for a visa or work permit. This opens
                  numerous job and educational opportunities across the
                  continent.
                </li>
                <li>
                  <Strong>Access to Public Services: </Strong>
                  As a Spanish citizen, you have the right to use the public
                  health system and education in Spain and other EU countries.
                  This includes free or low-cost healthcare and access to public
                  universities.
                </li>
                <li>
                  <Strong>Consular Protection: </Strong>
                  Spanish citizens can receive assistance from Spanish embassies
                  and consulates anywhere in the world. This provides a valuable
                  safety net during international travel.
                </li>
                <li>
                  <Strong>Political Participation: </Strong>
                  Spanish citizenship grants the right to vote in local,
                  regional, national, and European elections. This allows for
                  active participation in the political and democratic life of
                  the country.
                </li>
                <li>
                  <Strong>Ease of Travel: </Strong>
                  The Spanish passport is one of the most powerful in the world,
                  allowing visa-free travel to numerous countries. This greatly
                  simplifies travel and international tourism procedures.
                </li>
              </List>
              <Text justify>
                In summary, Spanish citizenship not only offers benefits within
                Spain but also opens doors across the European Union and
                facilitates international travel, providing a wide range of
                opportunities and protections.
              </Text>
            </ContentSection>
          </Section>
        </ContentWrapper>
      </Main>
      <Footer>
        <A href="mailto: info@hoxtonconsulting.com">
          info@hoxtonconsulting.com
        </A>
      </Footer>
    </Root>
  );
};

/* Styles */

const Root = styled.div`
  font-family: ${(props) => props.theme.fonts.primary.regular};
  width: 100%;
`;
const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: stretch;
`;

const ContentWrapper = styled.div`
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
`;

const Footer = styled.div`
  background: #3db8b1;
  width: 100%;
  text-align: center;
  padding: 20px 0px;
`;

const Section = styled.div`
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.surface ? "#e4edf2" : "transparent")};
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;

  ${(props) => props.theme.mui.breakpoints.up("md")} {
    max-width: 55%;
  }
`;

const SectionDescent = styled.h2`
  /* H2 */
  font-family: ${(props) => props.theme.fonts.primary.regular};
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  padding: 0 20px;
  color: #00426b;
  margin: 20px 0px;

  ${(props) => props.theme.mui.breakpoints.up("md")} {
    font-size: 32px;
    line-height: 34px;
  }
`;

const ContentImageHero = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  background-image: url(${(props) =>
    props.mobile}); /* Imagen de fondo para móviles */
  background-position: -200px -40px;
  background-size: 200%;
  background-repeat: no-repeat;

  ${(props) => props.theme.mui.breakpoints.up("sm")} {
    background-position: 0 -50px;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  ${(props) => props.theme.mui.breakpoints.up("md")} {
    background-image: url(${(props) =>
      props.desktop}); /* Imagen de fondo para escritorio */
    min-height: 390px;
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  ${(props) => props.theme.mui.breakpoints.up("lg")} {
    background-position: -100px -80px;
    background-size: 110%;
  }
`;

const Text = styled.p`
  text-align: ${(props) => (props.justify ? "justify" : "center")};
  line-height: 30px;
  color: #00426b;
`;

const Strong = styled.p`
  font-weight: 800;
  display: inline;
`;

const List = styled.ul`
  text-align: left;
  line-height: 30px;
  color: #00426b;
`;

const A = styled.a`
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;

export default SpanishCitizenshipPage;
