import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/HomePage";
import SpanishCitizenship from "./pages/SpanishCitizenship";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "spanish-citizenship",
    element: <SpanishCitizenship />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
