import React, { useState } from "react";
import Sticky from "react-stickynode";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import logo from "../images/logo.svg";

import NavLinkList, { NavLink } from "./NavLinkList";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import MailIcon from "@material-ui/icons/MailOutline";

import { Hidden } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "./ModernMobileDrawer";

const Nav = ({ showsBorder, sticky, backgroundColor }) => {
  const links = [
    {
      title: "Owner Representation",
      url: "/?#01",
    },
    {
      title: "Real Estate",
      url: "/?#02",
    },
    {
      title: "Corporate Services",
      url: "/?#03",
    },
    {
      title: "Business Consulting",
      url: "/?#04",
    },
    {
      title: "Spanish Citizenship",
      url: "/spanish-citizenship",
    },
  ];

  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleDrawerButtonClick = () => {
    setDrawerVisible(true);
  };

  const handleDrawerRequestClose = () => {
    setDrawerVisible(false);
  };

  const CloseButton = (
    <IconButton
      edge="end"
      color="inherit"
      aria-label="menu"
      onClick={handleDrawerRequestClose}
    >
      <CloseIcon />
    </IconButton>
  );

  const BurgerMenu = (
    <IconButton edge="end" aria-label="menu" onClick={handleDrawerButtonClick}>
      <MenuIcon />
    </IconButton>
  );

  return (
    <Sticky enabled={sticky} innerZ={999}>
      <Wrapper showsBorder={showsBorder} backgroundColor={backgroundColor}>
        <LogoLink to="/">
          <Logo src={logo} alt="logo" />
        </LogoLink>

        <RightContent>
          <Hidden mdup>
            <NavLinkList links={links} />
          </Hidden>

          <LoggedInBar>
            <Hidden smDown>
              <PhoneIconWrapper />
              <A href="mailto: info@hoxtonconsulting.com">
                info@hoxtonconsulting.com
              </A>
            </Hidden>
            <Hidden mdUp>{drawerVisible ? CloseButton : BurgerMenu}</Hidden>
          </LoggedInBar>
        </RightContent>
        <Hidden mdUp>
          <Drawer
            visible={drawerVisible}
            onRequestClose={handleDrawerRequestClose}
            items={links}
          />
        </Hidden>
      </Wrapper>
    </Sticky>
  );
};

/**
 * Constants
 */

Nav.HEIGHT = 80;

/**
 * PropTypes
 */

Nav.propTypes = {
  showsBorder: PropTypes.bool,
  sticky: PropTypes.bool,
  isHome: PropTypes.bool,
  Logo: PropTypes.func,
  ThemeProvider: PropTypes.func,
  backgroundColor: PropTypes.string,
};

Nav.defaultProps = {
  showsBorder: false,
  sticky: false,
  isHome: false,
  backgroundColor: "#fff",
};

/**
 * Styles
 */

const Wrapper = styled.div`
  width: 100%;
  padding-top: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.background};
  height: ${(props) =>
    props.theme.navHeight ? props.theme.navHeight : `${Nav.HEIGHT}px`};
  border-bottom: ${(props) =>
    props.showsBorder ? "solid 1px #e3e3e3" : "none"};

  ${(props) => props.theme.mui.breakpoints.up("md")} {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  padding-left: 40px;
  padding-right: 40px;
`;

const Logo = styled.img`
  height: 40px;
  pointer-events: none;
  margin: 20px;
`;

const logoLinkStyles = css`
  margin: 0px;
  margin-left: 10px;
  position: relative;
  top: 2px;

  ${(props) => props.theme.mui.breakpoints.up("md")} {
    margin-left: 0px;
  }
`;

const LogoLink = styled(NavLink)`
  ${logoLinkStyles}
`;
const RightContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  justify-content: flex-end;
  position: relative;
`;

const LoggedInBar = styled.div`
  display: flex;
  align-items: center;
`;

const A = styled.a`
  font-size: 12px;
  color: #3db8b1;
  text-decoration: none;
  cursor: pointer;
`;

const PhoneIconWrapper = styled(MailIcon)`
  font-size: 12px;
  color: #3db8b1;
  margin: 0px 6px;
`;
/**
 * Exports
 */

export default Nav;
