import React from "react";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  link: {
    color: "#1da850",
    margin: "0 5px",
    fontWeight: 800,

    "&:hover": {
      textDecoration: "underline", // Subrayado al pasar el cursor
    },
  },
});

const WhatsappContact = () => {
  const classes = useStyles();
  const phoneNumber = "17868320763"; // Número en formato para el enlace

  return (
    <Typography
      variant="body1"
      style={{
        color: "#00426b",
        margin: "-10px 0 10px 0",
        textAlign: "center",
      }}
    >
      Reach out via WhatsApp at
      <Link
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        +1 786 832 0763
      </Link>
      or fill in the form for more details
    </Typography>
  );
};

export default WhatsappContact;
